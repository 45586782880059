/*
 * YourIR loader for Clinuvel Pharmaceuticals Limited Investor Relations
 * (c) 2017 Iguana2 Pty. Ltd.
 *
 * Strictly for the sole use of Clinuvel Pharmaceuticals Limited on its Investor Relations web pages only
 *
 * Last updated: 2017-12-26T23:12:20Z
 */
!function(e,a){"use strict";var o=(e.document,e.location,e.yourirSetup),t={version:"1.9.4",startup:{processLocationHash:!0,addLoadedCSS:!0,addStyleSheet:!1,removeLoadingCSS:!0},autoUpdate:{enable:!0,libVersion:"1.9.4",loaderChecksum:"3be5c47039fb60f827eede6cff399ef2"},preload:{enable:!0,symbolData:!0},theme:"default",contexts:{default:{symbol:"cuv.asx",allowedSymbols:["cuv.asx"],consolidate:!0,streamUpdates:!1}},components:{defaults:{liveness:"delayed"},announcements:{includeOtherIssuers:!1,liveness:"live"},priceComparisonChart:{comparisonSymbol1:"xjo.asx"}},resources:{check:!1}},s=[t];if(t.appID=/* this appID (Application Identifier) is strictly for the sole use of Clinuvel Pharmaceuticals Limited */"fee77b1d1a878633",e.yourirAutoUpdate&&t.autoUpdate&&t.autoUpdate.enable)return void e.yourirAutoUpdate(t.autoUpdate.loaderChecksum);o&&s.push(o),e.yourirSetup=function(e){e(s)},e.yourirSetup.appID=t.appID}(this);
/*
 * yourir v1.9.4 loader
 * (c) 2016 Iguana2 Pty. Ltd. - https://yourir.info/
 * License: Creative Commons Attribution No Derivatives 3.0 License
 * (http://creativecommons.org/licenses/by-nd/3.0/legalcode)
 */
(function(root,undefined){'use strict';var h=window.yourirLoader={},k=document.head||document.getElementsByTagName("head")[0];k&&l();var m,p=navigator.userAgent.match(/msie *([0-9]+)/i),q=(m=p?p[1]:0)&&10>m;if(m&&9>m)try{document.namespaces.add("yourir-vml","urn:schemas-microsoft-com:vml","#default#VML"),h.addVMLNameSpace=!1}catch(r){}var t=document.createElement("script");t.type="text/javascript";t.src="https://yourir.info/lib/1.9.4/yourir.js";t.async=!0;q||(t.crossOrigin="anonymous",t.integrity="sha256-E57zYHZtriZmRbK959wYagJ7B0thuCCmarH9pGIudg4= sha512-uY5WlUg98/r2beC3W9mIAfSaL4GbfzDRPRsn1nQOaet5e7EbozD/dsn0rT54Hr5EzhaW75TShRDOPX7M0ubI8Q==");
var u=k&&l();if(u)u.parentNode.insertBefore(t,u.nextSibling);else if(k)k.insertBefore(t,k.firstChild);else if(document.body)document.body.appendChild(t);else{var v=document.getElementsByTagName("script")[0];v&&v.parentNode.insertBefore(t,v)}var w="https://yourir.info/lib/1.9.4/yourir"+(q?"-ie":"")+".css",x=!0;
if(document.createStyleSheet)document.createStyleSheet(w,0);else{var y=document.createElement("link");y.type="text/css";y.rel="stylesheet";y.href=w;y.async=!0;q||(y.crossOrigin="anonymous",y.integrity="sha256-aCoXKgi363Hf2OsU5CLlxD4P4F7leQ6migfhRmDDxL8= sha512-WiB7bucGO6jSC7H7ovy12vYwnBRshlPWHvQTM89kJ4JIcvMlWcuUSo2I7h7M4m5X61kmZLKFOx9ZeF6m55Ezww==");var z=k&&k.getElementsByTagName("link")[0];z?z.parentNode.insertBefore(y,z):k?k.insertBefore(y,k.firstChild):x=!1}x&&(h.addStyleSheet=!1);
function l(){for(var d=k.getElementsByTagName("script"),b=0,e=d.length;b<e;b++){var c=d[b].src;if(c&&/yourir.info|yourir-loader[.]js/.test(c))return d[b]}return null};function B(d){function b(a,c){var b=d.match(new RegExp(a+"([.:/-]|$)",c?"":"i"));if(b&&(b[b.length-1]||"."!==b[b.length-3]||!A.test(b[b.length-2])))return b}function e(a){var b;for(b=1;b<a.length;b++)f&&a[b]===f?a[b]="{code}":g&&a[b]===g?a[b]="{market}":n&&a[b]===n&&(a[b]="{symbol}");b=a.index;var c=b+a[0].length;d=(0<b?d.substr(0,b):"")+a.slice(1).join("")+(c<d.length?d.substr(c):"")}function c(a){f=f?f.toLowerCase():"";g=g?g.toLowerCase():"";n=n?n.toLowerCase():f&&g?f+"."+g:"";return{path:d,a:g,
symbol:n,code:f,mark:a}}var a,f,g,n,A=/html?|php|asp/;return(a=b("([.:/-])(asx|nzx)([.:/-])([A-Z0-9]{3,6})"))?(g=a[2],f=a[4],e(a),c(4)):(a=b("([.:/-])([A-Z0-9]{3,6})([.:/-])(asx|nzx)"))?(f=a[2],g=a[4],e(a),c(4)):(a=b("([.:/-])([A-Z0-9]{3,6})",!0))?(f=a[2],e(a),c(3)):(a=b("([.:/-])(asx|nzx)"))?(g=a[2],e(a),c(2)):(a=b("([.:/-])([a-z0-9]{3})"))?(f=a[2],e(a),c(1)):c(0)}
function C(d,b,e){var c,a;e&&e.mark>b.mark?(c=e.symbol,a=e.code,b=e.a):(c=b.symbol,a=b.code,b=b.a);return c?d.replace(/(symbols\/)[^\/]+/,"$1"+c):a?d.replace(/(symbols\/)[^.]+/,"$1"+a):b?d.replace(/(markets\/)[^\/]+/,"$1"+b):d};function D(d,b){var e=0>d.indexOf("?")?"?":"&",c,a,f=[];for(c in b)b.hasOwnProperty(c)&&(a=b[c],void 0!==a&&null!==a&&(a=!0===a?"1":!1===a?"0":encodeURIComponent(a),f.push(encodeURIComponent(c)+"="+a)));f.sort();(c=f.length?f.join("&"):"")&&(c=(void 0===e?"?":e)+c);return d+c};h=window.yourirPreload={loading:!1,requests:[]};try{"undefined"!==typeof JSON&&"undefined"!==typeof localStorage&&"undefined"!==typeof XMLHttpRequest&&"withCredentials"in new XMLHttpRequest&&(h.requests=E(),h.loading=!0)}catch(F){}
function E(){var d,b,e,c,a,f;a=B(location.pathname);try{window!==window.top&&(f=B(window.top.location.pathname))}catch(g){}h.path=a.path;e=(e=window.yourirSetup)&&e.appID?e.appID:localStorage.getItem("yourir-app-id");if(!e)return[];d=localStorage.getItem("yourir-"+(e+"-preload-requests-"+a.path));if(!d)return[];c=JSON.parse(d).requests;if(!c||!c.length)return[];d=0;for(b=c.length;d<b;d++)G(c[d],e,a,f||{});return c}
function G(d,b,e,c){var a;!1!==d.modify?(e=C(d.uri,e,c),d.uri=e):e=d.uri;b=D("https://yourir.info/api/v4"+e+"?appID="+b,d.params);a=new XMLHttpRequest;a.open("GET",b,!0);a.async=!0;a.onreadystatechange=function(){if(4===a.readyState){var b=d.hook;b?b(d,a):d.xhr=a}};a.send()};}(this));

